import React, { Suspense, lazy } from 'react';

import { ErrorBoundary } from './components/Error';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import VoucherProvider from './store/voucherProvider';
import InvoiceProvider from './store/invoiceProvider';
import { PostHogProvider } from 'posthog-js/react';
import OfframpPaymentProvider from './store/offrampPaymentProvider';

const Pay = lazy(() => import('./components/Pay'));
const Footer = lazy(() => import('./components/Footer'));
const Header = lazy(() => import('./components/Header'));
const Expired = lazy(() => import('./components/Expired'));
const Success = lazy(() => import('./components/Success'));
const Error = lazy(() => import('./components/Error/Error'));
const NotFound = lazy(() => import('./components/Error/404'));
const WrongAmount = lazy(() => import('./components/WrongAmount'));
const Payment = lazy(() => import('./components/Payment'));
const Spinner = lazy(() => import('./components/spinner'));

// const Demo = lazy(() => import('./components/Demo'));

//invoice
const InvoiceHeader = lazy(() => import('./components/Invoice/Header'));
const SignIn = lazy(() => import('./components/Invoice/SignIn'));
const CreateInvoice = lazy(
  () => import('./components/Invoice/CreateInvoice/index')
);
const InvoiceSuccess = lazy(() => import('./components/Invoice/Success'));

// Offramp
const OfframpIndex = lazy(() => import('./components/Offramp/index'));
const MakePayment = lazy(
  () => import('./components/Offramp/components/Payment')
);
const OfframpHeader = lazy(
  () => import('./components/Offramp/commonFeatures/Header')
);
const PaymentSuccess = lazy(
  () => import('./components/Offramp/components/Success')
);

const PendingConfirmation = lazy(
  () => import('./components/Offramp/components/PendingConfirmation')
);

const PaymentFailed = lazy(
  () => import('./components/Offramp/components/Failed')
);

const Payments = () => {
  return (
    <VoucherProvider>
      <div className="mainApp animate__animated animate__fadeIn td-200">
        <Header />
        <Routes>
          <Route path="/pay" element={<Pay />} />
          <Route path="/wrongamount" element={<WrongAmount />} />
          <Route path="/expired" element={<Expired />} />
          <Route path="/success" element={<Success />} />
        </Routes>
        <Footer />
      </div>
    </VoucherProvider>
  );
};

const Invoice = () => {
  return (
    <InvoiceProvider>
      <div className="mainApp animate__animated animate__fadeIn td-200">
        <InvoiceHeader />

        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/create" element={<CreateInvoice />} />
          <Route path="/success" element={<InvoiceSuccess />} />
        </Routes>
      </div>
    </InvoiceProvider>
  );
};

const Offramp = () => {
  return (
    <OfframpPaymentProvider>
      <div className="mainApp animate__animated animate__fadeIn td-200">
        <OfframpHeader />
        <Routes>
          <Route path="/pay" element={<OfframpIndex />} />
          <Route path="/makePayment" element={<MakePayment />} />
          <Route path="/success" element={<PaymentSuccess />} />
          <Route
            path="/pendingconfirmation"
            element={<PendingConfirmation />}
          />
          <Route path="/failed" element={<PaymentFailed />} />
        </Routes>
        <Footer />
      </div>
    </OfframpPaymentProvider>
  );
};

// const LandingpageHeader = lazy(() => import('./components/LandingPage/Header'));
// const OnrampPage = lazy(() => import('./components/LandingPage/OnrampPage'));
// const OfframpPage = lazy(() => import('./components/LandingPage/OfframpPage'));
const Landingpage = lazy(() => import('./components/LandingPage/landingPage'));

// const LandingpageRoute = () => {
//   return (
//     <div className="mainApp animate__animated animate__fadeIn td-200">
//       <LandingpageHeader />
//       <Suspense
//         fallback={
//           <div
//             style={{
//               marginTop: 30,
//               width: 390,
//             }}
//           />
//         }
//       >
//         <Routes>
//           <Route path="/" element={<Landingpage />} />
//           <Route path="/buy-crypto" element={<OnrampPage />} />
//           <Route path="/sell-crypto" element={<OfframpPage />} />
//         </Routes>
//       </Suspense>
//       <Footer />
//     </div>
//   );
// };

// const PartnerDemo = () => {
//   return (
//     <div className="mainApp animate__animated animate__fadeIn td-200">
//       <Suspense
//         fallback={
//           <div
//             style={{
//               marginTop: 30,
//               width: 390,
//             }}
//           />
//         }
//       >
//         <Routes>
//           <Route path="/" element={<Demo />} />
//         </Routes>
//       </Suspense>
//       <Footer />
//     </div>
//   );
// };

const options = {
  api_host: 'https://eu.posthog.com',
};

const App = () => {
  return (
    <>
      <PostHogProvider
        apiKey={process.env?.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
      >
        <BrowserRouter>
          <ErrorBoundary>
            <Suspense fallback={<Spinner />}>
              <Routes>
                {/* <Route path="/" element={<LandingpageRoute />} /> */}
                <Route path="/" element={<Landingpage />} />
                <Route path="/error" element={<Error />} />
                <Route path="/payment/:id" element={<Payment />} />
                <Route path="/voucher/*" element={<Payments />} />
                <Route path="/demo/voucher/*" element={<Payments />} />
                <Route path="/invoice/*" element={<Invoice />} />
                <Route path="/:version/voucher/*" element={<Payments />} />
                <Route path="/offramp/*" element={<Offramp />} />
                {/* <Route path="/service/*" element={<LandingpageRoute />} /> */}
                <Route path="/demo/offramp/*" element={<Offramp />} />
                <Route path="/service/*" element={<Landingpage />} />
                <Route path="/*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </BrowserRouter>
      </PostHogProvider>
    </>
  );
};

export default App;
